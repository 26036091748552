import * as React from 'react';

import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import DefaultImage from '../../assets/images/history.png';
import ButtonLink from '../LinkButton/LinkButton';

import './MemberProfileBlock.scss';

const MemberProfileBlock = ({ visuel, fullname, post, link }) => {
  return (
    <div className="component_member_profile_block">
      <div className="visuel">
        {
          visuel ? (
            <>
              <div className="only_mobile_tablet">
                <Img
                  style={{ height: "auto", width: "100%" }}
                  fluid={{ ...visuel, aspectRatio: 1 }}
                />
              </div>
              <div className="only_desktop">
                <Link to={link}>
                  <Img
                    style={{ height: "auto", width: "100%" }}
                    fluid={{ ...visuel, aspectRatio: 1 }}
                  />
                </Link>
              </div>
            </>
          ) : (
            <img src={DefaultImage} alt="" />
          )
        }
      </div>
      <div className="info_member">
        <p className="big">{fullname}</p>
        <p>{post}</p>
      </div>
      {link &&
        <div className="btn_container">
          <ButtonLink
            label="Voir la biographie"
            link={link}
          />
        </div>
      }

    </div>
  );
};

MemberProfileBlock.defaultProps = {
  name: 'Default Nmae',
  post: 'Default Post',
};

export default MemberProfileBlock;
