import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../Layout"
import Seo from "../../../components/Seo/Seo"
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb'
import DocumentView from '../../../Molecules/DocumentView/DocumentView'
import PageBanner from "../../../components/PageBanner/PageBanner";
import TitleSection from '../../../Molecules/TitleSection/TitleSection'
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers'
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav'
import { Button } from '../../../Molecules/Button/Button'
import MemberProfileBlock from '../../../Molecules/MemberProfileBlock/MemberProfileBlock'
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import QuoteMark from '../../../Atoms/QuoteMark/QuoteMark'
import ListAdminMembers from "../../../components/ListAdminMembers/ListAdminMembers";
import IntroPage from "../../../components/ContentType/IntroPage/IntroPage";
import CommitteeDetail from "../../../components/ContentType/CommitteeDetail/CommitteeDetail";
import DirectionBlock from "../../../components/ContentType/DirectionBlock/DirectionBlock";
import ListDirectionMember from "../../../components/ContentType/ListDirectionMember/ListDirectionMember";

import TitlePage from '../../../Molecules/TitlePage/TitlePage'

import './styles.scss'
import TitleBlock from "../../../Molecules/TitleBlock/TitleBlock";

var classNames = require('classnames');


const AboutUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "slider-image.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const list_board_of_directors = [
    {
      visuel: data.file.childImageSharp.fluid,
      fullname: 'Nom Prénom',
      post: 'Post occupé',
      link: '#'
    },
    {
      visuel: data.file.childImageSharp.fluid,
      fullname: 'Nom Prénom',
      post: 'Post occupé',
      link: '#'
    },
    {
      visuel: data.file.childImageSharp.fluid,
      fullname: 'Nom Prénom',
      post: 'Post occupé',
      link: '#'
    }
  ]

  const list_directtion_member = [
    {
      visuel: data.file.childImageSharp,
      name: 'Nom Prénom',
      post: 'Post occupé',
      dob: '05 mars 1988',
      textDetails: "Diplômé de l'École Centrale de Paris et de l'École Polytechnique de Stockholm, Frédéric Gardès, commence son parcours professionnel en 1994 au sein du groupe Bouygues en tant qu’ingénieur Travaux chez Bouygues Offshore. De 2002 à 2011, il poursuit sa carrière chez Saipem à des postes de direction de grands projets internationaux. En 2011, il rejoint Colas en tant que directeur de GTOI, filiale réunionnaise. Il prend ensuite la tête de la direction régionale Océan Indien en 2013 puis devient Directeur Général Adjoint Europe du Nord et Moyen-Orient en 2015. En 2018, il est nommé Directeur Général International (hors Amériques), Réseaux, Grands Projets. Le 14 mai 2019, Frédéric Gardès prend les fonctions de Directeur Général de la société Colas et, depuis le 16 février 2021, celles de Président du Conseil d’administration."
    },
    {
      visuel: data.file.childImageSharp,
      name: 'Nom Prénom',
      post: 'Post occupé',
      dob: '05 mars 1988',
      textDetails: "Diplômé de l'École Centrale de Paris et de l'École Polytechnique de Stockholm, Frédéric Gardès, commence son parcours professionnel en 1994 au sein du groupe Bouygues en tant qu’ingénieur Travaux chez Bouygues Offshore. De 2002 à 2011, il poursuit sa carrière chez Saipem à des postes de direction de grands projets internationaux. En 2011, il rejoint Colas en tant que directeur de GTOI, filiale réunionnaise. Il prend ensuite la tête de la direction régionale Océan Indien en 2013 puis devient Directeur Général Adjoint Europe du Nord et Moyen-Orient en 2015. En 2018, il est nommé Directeur Général International (hors Amériques), Réseaux, Grands Projets. Le 14 mai 2019, Frédéric Gardès prend les fonctions de Directeur Général de la société Colas et, depuis le 16 février 2021, celles de Président du Conseil d’administration."
    },
    {
      visuel: data.file.childImageSharp,
      name: 'Nom Prénom',
      post: 'Post occupé',
      dob: '05 mars 1988',
      textDetails: "Diplômé de l'École Centrale de Paris et de l'École Polytechnique de Stockholm, Frédéric Gardès, commence son parcours professionnel en 1994 au sein du groupe Bouygues en tant qu’ingénieur Travaux chez Bouygues Offshore. De 2002 à 2011, il poursuit sa carrière chez Saipem à des postes de direction de grands projets internationaux. En 2011, il rejoint Colas en tant que directeur de GTOI, filiale réunionnaise. Il prend ensuite la tête de la direction régionale Océan Indien en 2013 puis devient Directeur Général Adjoint Europe du Nord et Moyen-Orient en 2015. En 2018, il est nommé Directeur Général International (hors Amériques), Réseaux, Grands Projets. Le 14 mai 2019, Frédéric Gardès prend les fonctions de Directeur Général de la société Colas et, depuis le 16 février 2021, celles de Président du Conseil d’administration."
    },
    {
      visuel: data.file.childImageSharp,
      name: 'Nom Prénom',
      post: 'Post occupé',
      dob: '05 mars 1988',
      textDetails: "Diplômé de l'École Centrale de Paris et de l'École Polytechnique de Stockholm, Frédéric Gardès, commence son parcours professionnel en 1994 au sein du groupe Bouygues en tant qu’ingénieur Travaux chez Bouygues Offshore. De 2002 à 2011, il poursuit sa carrière chez Saipem à des postes de direction de grands projets internationaux. En 2011, il rejoint Colas en tant que directeur de GTOI, filiale réunionnaise. Il prend ensuite la tête de la direction régionale Océan Indien en 2013 puis devient Directeur Général Adjoint Europe du Nord et Moyen-Orient en 2015. En 2018, il est nommé Directeur Général International (hors Amériques), Réseaux, Grands Projets. Le 14 mai 2019, Frédéric Gardès prend les fonctions de Directeur Général de la société Colas et, depuis le 16 février 2021, celles de Président du Conseil d’administration."
    },
    {
      visuel: data.file.childImageSharp,
      name: 'Nom Prénom',
      post: 'Post occupé',
      dob: '05 mars 1988',
      textDetails: "Diplômé de l'École Centrale de Paris et de l'École Polytechnique de Stockholm, Frédéric Gardès, commence son parcours professionnel en 1994 au sein du groupe Bouygues en tant qu’ingénieur Travaux chez Bouygues Offshore. De 2002 à 2011, il poursuit sa carrière chez Saipem à des postes de direction de grands projets internationaux. En 2011, il rejoint Colas en tant que directeur de GTOI, filiale réunionnaise. Il prend ensuite la tête de la direction régionale Océan Indien en 2013 puis devient Directeur Général Adjoint Europe du Nord et Moyen-Orient en 2015. En 2018, il est nommé Directeur Général International (hors Amériques), Réseaux, Grands Projets. Le 14 mai 2019, Frédéric Gardès prend les fonctions de Directeur Général de la société Colas et, depuis le 16 février 2021, celles de Président du Conseil d’administration."
    }
  ]

  return (
    <Layout>
      <Seo
        title="Colas About US"
        description="Description de la page About us"
      />
      <div className={classNames('page_template', 'page_gouvernance')}>
        <ScrollPageNav
          data={[
            {
              title: 'Conseil d’Administration',
              section: 'intro_page_component'
            },
            {
              title: 'Composition du Conseil d’administration',
              section: 'section_board_of_directors'
            },
            {
              title: 'Les comités',
              section: 'section_list_committee'
            }, {
              title: 'Direction',
              section: 'section_direction'
            }, {
              title: 'Documentation',
              section: 'section_documents'
            }
          ]}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: "Acceuil", url: "/" },
                parentPage: { title: "Groupe", url: "/groupe" },
                currentPage: { title: "Qui somme nous", url: "/about-us" }
              }}
            />
            <TitlePage
              color="color_white"
              title="La gouvernance"
              description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
            />
          </div>
        </PageBanner>
        <IntroPage
          customClass="topZIndex"
          title="Conseil d’Administration"
          subtitle="Le Conseil d’administration se réunit cinq fois par an en séance ordinaire :"
          size="border" //wrapper
          text="En janvier, le Conseil examine le plan d’affaires à trois ans. En février, il arrête les comptes de l’exercice précédent. En août, il s’informe sur l’activité du premier semestre et examine les comptes du premier semestre, analyse l’activité et les résultats estimés de l’exercice. En mai et novembre, il examine les indicateurs d’activité et de résultats intermédiaires."
        />
        <section className={classNames('section_content', 'section_board_of_directors')}>
          <div className="wrapper_page_xs">
            <TitleSection
              title="Composition du Conseil d’administration"
              description="Le Conseil d’administration de Colas est composé de huit administrateurs :"
            />
          </div>
          <div className="wrapper_page">
            <ListAdminMembers
              listAdmins={list_board_of_directors}
            />
            <div className="quote_container">
              <QuoteMark
                lang="fr"
                text="Le Conseil est assisté dans l’exécution de sa mission par un Comité d’audit, un Comité de sélection et des rémunérations et un Comité de l’éthique et du mécénat."
              />
            </div>
            <DocumentView
              data={[
                {
                  name: "Nom du documents",
                  size: "(.pdf 320Ko)",
                  link: "/"
                },
                {
                  name: "Nom du documents",
                  size: "(.pdf 320Ko)",
                  link: "/"
                }
              ]}

            />
          </div>
        </section>
        <section className={classNames('section_content', 'section_list_committee')}>
          <ShapeBackground
            top="left"
            color="catskill_white"
            bottomInfinite={true}
          >
            <div className="wrapper_page">
              <CommitteeDetail
                title="Le Comité d’Audit"
                description="Le Comité d’audit se réunit quatre fois par an pour examiner les comptes consolidés et sociaux préalablement aux réunions du Conseil d’administration. Il a pour mission d’aider le Conseil à veiller à l’exactitude, la fiabilité et la sincérité de ces comptes et à la qualité de l’information qui est diffusée notamment vers les Actionnaires"
              />
              <CommitteeDetail
                title="Le Comité d’Audit"
                description="Le Comité d’audit se réunit quatre fois par an pour examiner les comptes consolidés et sociaux préalablement aux réunions du Conseil d’administration. Il a pour mission d’aider le Conseil à veiller à l’exactitude, la fiabilité et la sincérité de ces comptes et à la qualité de l’information qui est diffusée notamment vers les Actionnaires"
              />
              <CommitteeDetail
                title="Le Comité d’Audit"
                description="Le Comité d’audit se réunit quatre fois par an pour examiner les comptes consolidés et sociaux préalablement aux réunions du Conseil d’administration. Il a pour mission d’aider le Conseil à veiller à l’exactitude, la fiabilité et la sincérité de ces comptes et à la qualité de l’information qui est diffusée notamment vers les Actionnaires"
              />
            </div>
          </ShapeBackground>
        </section>
        <section className={classNames('section_content', 'section_direction')}>
          <ShapeBackground
            top="left"
            bottom="left"
          >
            <div className="wrapper_page_xs">
              <TitleSection
                title="Direction"
                h2color="white"
              />
            </div>
            <div className="wrapper_page">
              <div className="btn_container">
                <Button
                  label="comité de direction"
                  link="/"
                />
                <Button
                  label="comité de direction"
                  link="/"
                />
              </div>
            </div>
            <div className="wrapper_page_xs">
              <ListDirectionMember
                listDirectionMember={list_directtion_member}
              />
            </div>
          </ShapeBackground>
        </section>
        <section className={classNames('section_content', 'section_documents')}>
          <div className="wrapper_page_xs">
            <TitleSection
              title="Documents"
            />
            <div className="document_type">
              <TitleBlock
                title="Convention réglementées"
              />
              <p>loreme aziea ozeha zeohae</p>
              <Button
                label="Découvrir la charte"
                link="/"
              />
              <DocumentView
                data={[
                  {
                    name: "Nom du documents",
                    size: "(.pdf 320Ko)",
                    link: "/"
                  },
                  {
                    name: "Nom du documents",
                    size: "(.pdf 320Ko)",
                    link: "/"
                  }
                ]}
              />
            </div>
            <div className="document_type">
              <TitleBlock
                title="Convention réglementées"
              />
              <p>loreme aziea ozeha zeohae</p>
              <DocumentView
                data={[
                  {
                    name: "Nom du documents",
                    size: "(.pdf 320Ko)",
                    link: "/"
                  },
                  {
                    name: "Nom du documents",
                    size: "(.pdf 320Ko)",
                    link: "/"
                  }
                ]}
              />
            </div>
            <div className="document_type">
              <TitleBlock
                title="Convention réglementées"
              />
              <p>loreme aziea ozeha zeohae</p>
              <DocumentView
                data={[
                  {
                    name: "Nom du documents",
                    size: "(.pdf 320Ko)",
                    link: "/"
                  },
                  {
                    name: "Nom du documents",
                    size: "(.pdf 320Ko)",
                    link: "/"
                  }
                ]}
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )

}

export default AboutUsPage
